/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-06-30 13:16:41
 * @LastEditors: Nick juntong.wu@travel-easy.com
 * @LastEditTime: 2024-07-10 15:47:47
 * @FilePath: /base-frontend/src/core/directive/function/common.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { ComputedRef, computed } from "vue";
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import fileDownload from "js-file-download";
import { CommonDateType, CommonLanguageType } from "../type/common";
import { CommonModuleBCN, CommonModuleRouter } from "../interface/common";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { getUserManagementDetailModule, getUserManagementModule } from "./user";
import { getGeneralInformationModule } from "./generalInformation";

export const formatUtcDate = (date: CommonDateType, format = "YYYY-MM-DD") => {
  return date ? moment(date).utcOffset(0).format(format) : "";
};

/**
 * @description: 获取列表
 * @return CommonModuleRouter
 */
export const getModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  if (route.path.includes("/master-data/")) {
    return getGeneralInformationModule(language, route);
  } else if (route.path.includes("/user-management/")) {
    return getUserManagementModule(language, route);
  }
  return getGeneralInformationModule(language, route);
};

/**
 * @description: 获取详情
 * @return CommonModuleRouter
 */
export const getDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  if (route.path.includes("/user-management/")) {
    return getUserManagementDetailModule(language, route, router);
  }
  return getUserManagementDetailModule(language, route, router);
};

/**
 * @description: 设置BCN
 * @return CommonModuleRouter
 */
export const setModuleBCN = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router?: Router
): void => {
  if (router) {
    const moudleResult = getDetailModule(language, route, router);
    const arr = setParentBCN(moudleResult);
    setCurrentPageBreadcrumbs(moudleResult.currentName, [
      ...arr,
      {
        name: moudleResult.listName,
        path: moudleResult.listPath,
      },
    ]);
  } else {
    const moudleResult = getModule(language, route);
    const arr = setParentBCN(moudleResult);
    setCurrentPageBreadcrumbs(moudleResult.currentName, arr);
  }
};

const setParentBCN = (module: CommonModuleRouter) => {
  const arr: CommonModuleBCN[] = [];
  module.parentName.map((item) => {
    arr.push({
      name: item,
      path: "",
    });
  });
  return arr;
};

/**
 * @description: modal显示监听器
 * @param {*} modalEl
 * @param callback
 * @return {*}
 */
export const modalShowListener = (
  modalEl: HTMLElement | null,
  callback
): void => {
  if (!modalEl) {
    return;
  }
  modalEl.addEventListener("show.bs.modal", callback);
};

/**
 * @description: 公共下载文件流方法
 * @param {*} data
 * @return {*}
 */
export const commonExportFile = (data, name?) => {
  console.log(" commonExportFile ");
  const fileReader = new FileReader();
  fileReader.onload = function () {
    console.log("fileReader.onload");
    try {
      // 说明是普通对象数据，后台转换失败
      const jsonData = JSON.parse(this.result as string);
      showServerErrorMsg(jsonData);
    } catch (err) {
      // 解析成对象失败，说明是正常的文件流
      console.log(" 文件流 ");
      const disposition = data.headers["content-disposition"];
      if (!name) {
        const fileNameString = disposition.split("filename=")[1];
        let fileName = fileNameString.replaceAll('"', "").replaceAll("'", "");
        if (fileName.indexOf("filename*=utf-8") > -1) {
          fileName = decodeURI(fileName.split("filename*=utf-8")[1]);
        }
        fileName = decodeURIComponent(fileName);
        fileDownload(data.data, decodeURI(fileName));
      } else {
        const fileName = decodeURIComponent(name);
        fileDownload(data.data, decodeURI(fileName));
      }
    }
  };
  fileReader.readAsText(data.data);
};

/**
 * @description: 显示服务端错误消息弹框
 * @param {*} res
 * @return {*}
 */
const showServerErrorMsg = (res) => {
  const errorMsg = res.data;
  let htmlMsg = "";
  if (errorMsg) {
    for (const key in errorMsg) {
      htmlMsg += errorMsg[key][0] + "<br/>";
    }
  }
  if (res.sub_code.indexOf("FORM-VALIDATION") != -1) {
    //服务端数据验证错误
    Swal.fire({
      title: res.sub_msg,
      icon: "error",
      html: htmlMsg,
    });
  } else {
    //服务端其他错误
    Swal.fire({
      title: res.msg,
      text: res.sub_msg,
      icon: "error",
    });
  }
};

/**
 * @description: 创建一个带缓存的计算函数，提高性能
 * @param {*} fn
 * @return {*}
 */
export const useComputedFn = <T, U extends unknown[]>(
  fn: (...args: U) => T
): ((...args: U) => ComputedRef<T>) => {
  const cache: Map<string, ComputedRef<T>> = new Map();
  return (...args: U): ComputedRef<T> => {
    const cacheKey = JSON.stringify(args);
    if (cache.has(cacheKey)) return cache.get(cacheKey) as ComputedRef<T>;
    const result = computed(() => fn(...args));
    cache.set(cacheKey, result);
    return result;
  };
};
