/*
 * @Author: Nick juntong.wu@travel-easy.com
 * @Date: 2024-07-10 15:51:51
 * @LastEditors: Nick juntong.wu@travel-easy.com
 * @LastEditTime: 2024-07-10 15:52:41
 * @FilePath: \base-frontend\src\core\directive\type\generalInformation.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export enum generalInformationStatus {
  Draft = 5,
  Activated = 10,
  Pending = 15,
  Rejected = 20,
  Deactivated = 25,
  Locked = 30,
  Logout = 35,
  Deleted = 90,
  Archived = 100,
}
