/*
 * @Author: Nick juntong.wu@travel-easy.com
 * @Date: 2024-07-10 15:18:07
 * @LastEditors: Nick juntong.wu@travel-easy.com
 * @LastEditTime: 2024-07-10 15:49:57
 * @FilePath: \base-frontend\src\core\directive\function\generalInformation.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { BadgeStyle, CommonLanguageType, NumberOrString } from "../type/common";
import { generalInformationStatus } from "@/core/directive/type/generalInformation";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getGeneralInformationModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: [],
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setGeneralInformationDetailName(language, route, result);
  return result;
};

/**
 * @description: 获取列表path和name
 * @return CommonModuleRouter
 */
const setGeneralInformationDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/master-data/";
  result.parentName = [language("generalInformation.title")];
  if (route.path.includes("/master-data")) {
    strName = language("generalInformation.cityManagement.title");
    listPath += "master-data";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 基础信息状态badge
 * @param {NumberOrString} status
 * @param {CommonLanguageType} language
 * @return {*}
 */
export const getGeneralInformationStatus = (
  status: NumberOrString,
  language: CommonLanguageType
) => {
  let color = "",
    title = "";
  const currentStatus = Number(status) as generalInformationStatus;
  switch (currentStatus) {
    case generalInformationStatus.Draft:
      color = BadgeStyle.LightPrimary;
      title = language("common.draft");
      break;
    case generalInformationStatus.Activated:
      color = BadgeStyle.LightSuccess;
      title = language("common.activated");
      break;
    case generalInformationStatus.Pending:
      color = BadgeStyle.LightWarning;
      title = language("common.pending");
      break;
    case generalInformationStatus.Rejected:
      color = BadgeStyle.LightDanger;
      title = language("common.rejected");
      break;
    case generalInformationStatus.Deactivated:
      color = BadgeStyle.Light;
      title = language("common.deactivated");
      break;
    case generalInformationStatus.Locked:
      color = BadgeStyle.LightInfo;
      title = language("common.locked");
      break;
    case generalInformationStatus.Logout:
      color = BadgeStyle.Light;
      title = language("common.loggedOut");
      break;
    case generalInformationStatus.Deleted:
      color = BadgeStyle.LightDanger;
      title = language("common.deleted");
      break;
    case generalInformationStatus.Archived:
      color = BadgeStyle.Light;
      title = language("common.archived");
      break;
    default:
      // eslint-disable-next-line no-case-declarations
      const n: never = currentStatus;
      console.log(n);
      break;
  }
  return {
    color,
    title,
  };
};
