export type NumberOrString = number | string;

export type CommonDateType = string | null;

export type CommonLanguageType = (key: string | number) => string;

export enum BadgeStyle {
    White = "badge-white",
    Primary = "badge-primary",
    Light = "badge-light",
    Secondary = "badge-secondary",
    Success = "badge-success",
    Info = "badge-info",
    Warning = "badge-warning",
    Danger = "badge-danger",
    Dark = "badge-dark",
    LightPrimary = "badge-light-primary",
    LightSuccess = "badge-light-success",
    LightInfo = "badge-light-info",
    LightInfoCustom = "light-info-custom",
    LightWarning = "badge-light-warning",
    LightDanger = "badge-light-danger",
    LightSecondary = "badge-light-secondary",
    LightDark = "badge-light-dark",
}

export enum TextStyle {
    White = "text-white",
    Primary = "text-primary",
    Light = "text-light",
    Secondary = "text-secondary",
    Success = "text-success",
    Info = "text-info",
    Warning = "text-warning",
    Danger = "text-danger",
    Dark = "text-dark",
    LightPrimary = "text-light-primary",
    LightSuccess = "text-light-success",
    LightInfo = "text-light-info",
    LightWarning = "text-light-warning",
    LightDanger = "text-light-danger",
    LightDark = "text-light-dark",
}

export enum SvgStyle {
    White = "svg-icon-white",
    Muted = "svg-icon-muted",
    Primary = "svg-icon-primary",
    Light = "svg-icon-light",
    Secondary = "svg-icon-secondary",
    Success = "svg-icon-success",
    Info = "svg-icon-info",
    Warning = "svg-icon-warning",
    Danger = "svg-icon-danger",
    Dark = "svg-icon-dark",
    LightPrimary = "svg-icon-light-primary",
    LightSuccess = "svg-icon-light-success",
    LightInfo = "svg-icon-light-info",
    LightWarning = "svg-icon-light-warning",
    LightDanger = "svg-icon-light-danger",
    LightDark = "svg-icon-light-dark",
}

export enum BgStyle {
    White = "bg-white",
    Primary = "bg-primary",
    Light = "bg-light",
    Secondary = "bg-secondary",
    Success = "bg-success",
    Info = "bg-info",
    Warning = "bg-warning",
    Danger = "bg-danger",
    Dark = "bg-dark",
    LightPrimary = "bg-light-primary",
    LightSuccess = "bg-light-success",
    LightInfo = "bg-light-info",
    LightWarning = "bg-light-warning",
    LightDanger = "bg-light-danger",
    LightDark = "bg-light-dark",
}